import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
export default class Home extends Component {
  constructor(props) {
    super(props);
    // the initial application state
    this.state = {
      user_id: localStorage.getItem("user_id"),
    };
  }

  componentWillMount() {

  }

  render() {
    return (
      <div>
        {/** Header */}
        <div className="Header">
          <Container>
            <Row>
              <Col lg={6} xl={6} md={8} sm={12} xs={12} className="SafeerIntro">
                <img alt="Safeer" src="/img/logo.png" />

                <p>
                  Safeer Wallet’s E-Smart Wallet is a new way to pay friends,
                  family and business associates with an E-Smart Wallet account.
                  With E-Smart Wallet, each user can add money by linking a bank
                  account and managing money on their smartphone or computer
                  with your E-Smart Wallet dashboard. And with more amazing
                  features.
                </p>

                <a href="#download">Download</a>
              </Col>

              <Col lg={6} xl={6} md={4} sm={12} xs={12} className="PhoneSaffer">
                <img alt="Safeer" src="/img/phons.png" />
              </Col>
            </Row>
          </Container>
        </div>



        <div className="Services">
          <Container>
            <Row>
              <Col>
                <h1>Pay Your Bills Anywhere, Anytime</h1>
                <h4>Paying bills is much easier with safeer e-wallet</h4>
                <img src="Services" src="/img/Services.png" />
              </Col>
            </Row>
          </Container>
        </div>

        <br />
        <div className="Services">
          <Container>
            <Row>
              <Col>
                <h1>Transfer Easily</h1>
                <h4>send and receive money for free with only one click anywhere and anytime</h4>
                <img src="Services" src="/img/methods.png" />
              </Col>
            </Row>
          </Container>
        </div>


        <div className="OpenWallet" id="download">
          <Container>
            <Row>
              <Col lg={6} xl={6} md={6} sm={6} xs={12}>
                <div className="Open">
                  <h3>DIDN'T OPEN A WALLET YET?!</h3>
                  <h4>Download the app today and Register Online </h4>

                  <ul>
                    <li>
                      <a href="">
                        <i class="fa fa-apple" aria-hidden="true"></i>
                        App Store
                      </a>
                    </li>

                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.safeerwallet_user">
                        <i class="fa fa-android" aria-hidden="true"></i>
                        Google Play
                      </a>
                    </li>

                  </ul>
                </div>

              </Col>

              <Col lg={6} xl={6} md={6} sm={6} xs={12}>
                <img src="Services" src="/img/safeer.png" />
              </Col>
            </Row>
          </Container>
        </div>


        <div className="Services">
          <Container>
            <Row>
              <Col>
                <h1>One Wallet, No Limits</h1>  <br />
                <img src="Services" src="/img/aassas.png" />
              </Col>
            </Row>
          </Container>
        </div>


        <div className="Services">
          <Container>
            <Row>
              <Col>

                <img src="Services" src="/img/safeerrrr.png" />
              </Col>
            </Row>
          </Container>
        </div>



        <div className="Footer">
          <Container>
            <Row>
              <Col>
                <img alt="" src="/img/logo2.png" />
                <h2>TALK TO US!</h2>
                <h4>We’ll do anything to make your safeer wallet experience as good as possible. If you need any help, please contact our customer service at </h4>
                <a href="mailto:info@safeerwallet.com ">info@safeerwallet.com</a> <br /> <br />
                <ul>
                  <li>
                    <a href="https://www.facebook.com/safeerwallet">
                      <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>

                  {/* <li>
                    <a href="">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li> */}

                  <li>
                    <a href="https://www.instagram.com/safeerwallet">
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://youtube.com/channel/UC6Xwf5CI1aH-hGiCBvYL5Dw">
                      <i class="fa fa-youtube" aria-hidden="true"></i>
                    </a>
                  </li>

                </ul>
              </Col>
            </Row>
          </Container>
        </div>



        <div className="SubFooter">
          <Container>
            <Row>
              <Col>
                <h1>© 2020 safeerwallet. All rights reserved</h1>  <br />
              </Col>
              <Col> 
               <a href="https://www.natejsoft.com" target="_blank" ><img src="/img/natejsoft-logo.png" width="50%"  style={{ marginBottom: "15px"}}/></a> 
              </Col>
              <Col>
                <h1>An Natejsoft Products</h1>  <br />
              </Col>



            </Row>
          </Container>
        </div>



      </div>
    );
  }
}
