import React from "react";
import Home from './Pages/Home';
import { Route, BrowserRouter } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <React.Suspense>
      <BrowserRouter basename="/">
        <div>
          <div> 
            <Route path="/" component={Home} exact />
          </div>
        </div>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
